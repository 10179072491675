import { FormControl, Textarea } from '@mui/joy';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import PropTypes from 'prop-types';

const PrimaryTextarea = ({
  children,
  label,
  helperText,
  value,
  onChange,
  minRows,
  startDecorator,
  endDecorator,
  ...others
}) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Textarea
      variant="outlined"
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
      minRows={minRows}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      {...others}
    />
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

export default PrimaryTextarea;

PrimaryTextarea.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minRows: PropTypes.number,
  startDecorator: PropTypes.node,
  endDecorator: PropTypes.node,
  others: PropTypes.any,
};
