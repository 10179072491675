import { useCallback, useContext } from 'react';
import { userApi } from '../../../api/user/user';
import { UserContext } from '../../../auth/context/user';
import { useRouter } from '../../../routes/hook';
import { paths } from '../../../routes/paths';
import useRequest from '../../../standard/hooks/useRequest';

const useAuth = () => {
  const router = useRouter();
  const { onRequest, errors, isSuccess, isLoading } = useRequest();
  const { setUserContext } = useContext(UserContext);

  const onRegisterAccount = useCallback(
    async (username, email, password) => {
      const body = { username, email, password };
      const response = await onRequest(userApi.registerAccount, [], body, false);

      setUserContext({ userId: response?.result, username: '' });
    },
    [onRequest, setUserContext]
  );

  const onLogin = async (email, password) => {
    const body = {
      email,
      password,
    };
    const response = await onRequest(userApi.loginAccount, [], body, true);
    const result = response?.result;

    if (result == null) return;

    localStorage.setItem('token', result.accessToken);
    localStorage.setItem('expiry', result.expiry);
    setUserContext({ userId: result.userId, username: result.userName, userRole: result.userRole });

    if (result.accessToken.length > 0) {
      router.push(paths.dashboard.root);
    }
  };

  const onSignOut = async () => {
    localStorage.clear();
    setUserContext({ userId: '', username: '', userRole: '' });
    router.push(paths.login);
  };

  const tokenExpiry = localStorage.getItem('expiry');
  const tokenExpiryDate = tokenExpiry != null ? new Date(tokenExpiry).toLocaleString() : null;

  return {
    errors,
    isSuccess,
    isLoading,
    isAccessTokenExist: localStorage.getItem('token') != null,
    tokenExpiryDate,
    onRegisterAccount,
    onLogin,
    onSignOut,
  };
};

export default useAuth;
