import IconButton from '@mui/joy/IconButton';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import PrimaryBox from '../primary/primary-box';
import Word from '../primary/word';

const SubtitleBoxWithLeftButton = ({ children, title, menuItems, rightButtons, endDecorator, ...others }) => (
  <PrimaryBox {...others}>
    <PrimaryBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <PrimaryBox style={{ display: 'flex', alignItems: 'center' }}>
        {menuItems.map((item, i) => (
          <Fragment key={i}>
            {item.onClick ? (
              <IconButton onClick={item.onClick}>{item.icon}</IconButton>
            ) : (
              <>{item.icon}</>
            )}
          </Fragment>
        ))}
        <Word
          level="subtitle1"
          component="h2"
          sx={{ fontWeight: 'bold', marginLeft: '8px' }}
          endDecorator={endDecorator}
        >
          {title}
        </Word>
      </PrimaryBox>
      <PrimaryBox style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {rightButtons?.map((button, i) => (
          <Fragment key={i}>
            <>{button}</>
          </Fragment>
        ))}
      </PrimaryBox>
    </PrimaryBox>
    {children}
  </PrimaryBox>
);

export default SubtitleBoxWithLeftButton;

SubtitleBoxWithLeftButton.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  rightButtons: PropTypes.array,
  endDecorator: PropTypes.node,
  others: PropTypes.any,
};
