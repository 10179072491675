import { Button } from '@mui/joy';
import PropTypes from 'prop-types';

const SecondaryButton = ({ children, ...others }) => (
  <Button color="primary" variant="outlined" {...others}>
    {children}
  </Button>
);

export default SecondaryButton;

SecondaryButton.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
