import { ListItem } from '@mui/joy';
import PropTypes from 'prop-types';

const PrimaryListItem = ({ children, ...others }) => <ListItem {...others}>{children}</ListItem>;

export default PrimaryListItem;

PrimaryListItem.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
