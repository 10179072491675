// @mui
import { styled } from "@mui/joy/styles";
import { MaterialDesignContent } from "notistack";

// ----------------------------------------------------------------------

export const StyledNotistack = MaterialDesignContent;

// ----------------------------------------------------------------------

export const StyledIcon = styled('span')(({ color, theme }) => ({
  width: 44,
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
