import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/joy/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { calendarApi } from '../../../../api/calendar/calendarAdapter';
import { documentLineApi } from '../../../../api/document/documentLine';
import { UserContext } from '../../../../auth/context/user';
import { useBoolean } from '../../../../hooks/use-boolean';
import useString from '../../../../hooks/use-string';
import useRequest from '../../../../standard/hooks/useRequest';
import PrimaryBox from '../../../../standard/primary/primary-box';
import Word from '../../../../standard/primary/word';
import usePopover from '../editor/components/use-popover';
import ContentLineInsertInput from './content-line-insert-input';
import LineActionDetail from './line-action-detail';
import StatusSelector from './status-selector';

const ContentLineRow = ({ line, symbol, viewMode, onUpdate }) => {
  const { userId } = useContext(UserContext);
  const { onRequest } = useRequest();
  const popover = usePopover();

  const onUpdateStatus = useCallback(
    async (status) => {
      popover.onClose();
      if (status === 'duplicate') {
        if (viewMode.type === 'calendar') {
          await onRequest(calendarApi.duplicateCalendarLine, [userId], line, false);
        } else if (viewMode.type === 'document') {
          await onRequest(documentLineApi.duplicateDocumentLine, [userId], line, false);
        }
      } else if (status === 'delete') {
        if (viewMode.type === 'calendar') {
          await onRequest(calendarApi.removeCalendarLine, [userId], line, false);
        } else if (viewMode.type === 'document') {
          await onRequest(documentLineApi.removeDocumentLine, [userId], line, false);
        }
      } else {
        const body = { ...line, status };
        if (viewMode.type === 'calendar') {
          await onRequest(calendarApi.updateCalendarLine, [userId], body, false);
        } else if (viewMode.type === 'document') {
          await onRequest(documentLineApi.updateDocumentLine, [userId], body, false);
        }
      }

      await onUpdate();
    },
    [line, popover, viewMode.type, onUpdate, onRequest, userId]
  );

  const isHover = useBoolean(false);
  const insertDirection = useString('');

  const onChangeInsertDirection = useCallback((s) => {
    if (s === insertDirection.value) {
      insertDirection.onClear();
    } else {
      insertDirection.onChangeValue(s);
    }
  }, [insertDirection]);

  return (
    <>
      {!insertDirection.isEmpty && insertDirection.value === 'up' && (
        <ContentLineInsertInput
          viewMode={viewMode}
          line={line}
          isUp
          onClose={insertDirection.onClear}
          onUpdate={onUpdate}
        />
      )}
      <PrimaryBox
        onMouseEnter={isHover.onTrue}
        onMouseLeave={isHover.onFalse}
        sx={{ position: 'relative', display: 'inline-block' }}
      >
        <Word component="span" sx={{ cursor: 'pointer' }}>
          <StatusSelector
            popover={popover}
            line={line}
            symbol={symbol}
            onUpdateStatus={onUpdateStatus}
          />{' '}
          <LineActionDetail label={line.bracketWords} />{' '}
          {isHover.value && (
            <>
              <IconButton
                variant="outlined"
                size="small"
                onClick={() => onChangeInsertDirection('up')}
              >
                <ArrowDropUpIcon />
              </IconButton>
              <IconButton
                variant="outlined"
                size="small"
                onClick={() => onChangeInsertDirection('down')}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </>
          )}
        </Word>
      </PrimaryBox>
      {!insertDirection.isEmpty && insertDirection.value === 'down' && (
        <ContentLineInsertInput
          viewMode={viewMode}
          line={line}
          isUp={false}
          onClose={insertDirection.onClear}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default ContentLineRow;

ContentLineRow.propTypes = {
  line: PropTypes.object,
  symbol: PropTypes.string,
  viewMode: PropTypes.object,
  onUpdate: PropTypes.func,
};
