import { useState } from 'react';

const useString = (initialString) => {
  const [value, setValue] = useState(initialString ?? '');
  const isEmpty = value.length === 0;

  const onChangeValue = (newValue) => {
    setValue(newValue ?? '');
  };

  const onChangeValueByEvent = (e) => onChangeValue(e.target.value);

  const onClear = () => onChangeValue('');

  return {
    isEmpty,
    value,
    onChangeValue,
    onChangeValueByEvent,
    onClear,
  };
};

export default useString;
