import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notify = useCallback(
    (text, variant) => {
      if (text != null) {
        enqueueSnackbar(text, { variant });
      }
    },
    [enqueueSnackbar]
  );
  const notifySuccess = useCallback((text) => notify(text, 'success'), [notify]);
  const notifyError = useCallback((text) => notify(text, 'error'), [notify]);
  const notifyInfo = useCallback((text) => notify(text, 'info'), [notify]);

  return {
    notifySuccess,
    notifyError,
    notifyInfo,
  };
};

export default useNotification;
