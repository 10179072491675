import Box from '@mui/joy/Box';
import PropTypes from 'prop-types';

const PrimaryBox = ({ children, ...others }) => <Box {...others}>{children}</Box>;

export default PrimaryBox;

PrimaryBox.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
