import Card from '@mui/joy/Card';
import PropTypes from 'prop-types';
import PrimaryBox from '../primary/primary-box';

const PaperBox = ({ children, p, ...others }) => (
  <Card elevation={2} sx={{ p: p ?? 2 }} sx={{ backgroundColor: 'solid' }} {...others}>
    <PrimaryBox>{children}</PrimaryBox>
  </Card>
);

export default PaperBox;

PaperBox.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
  p: PropTypes.number,
};
