import PropTypes from 'prop-types';
import PrimaryBox from '../../../../standard/primary/primary-box';
import parseFinance from '../document-finance/parse-finance';
import ContentLine from './content-line';

const TextContent = ({ lines, viewMode, onClick, onUpdate }) => {
  const { amountSum, isEmpty } = parseFinance(lines);

  return (
    <PrimaryBox onDoubleClick={onClick}>
      {lines?.map((line) => (
        <ContentLine key={line.id} line={line} viewMode={viewMode} onUpdate={onUpdate} />
      ))}
      {!isEmpty && <>💰 total: ${amountSum}</>}
    </PrimaryBox>
  );
};

TextContent.propTypes = {
  lines: PropTypes.array,
  viewMode: PropTypes.object,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default TextContent;
