import Box from '@mui/joy/Box';
import PropTypes from 'prop-types';
import * as React from 'react';

function Header({ sx, ...props }) {
  return (
    <Box
      component="header"
      className="Header"
      {...props}
      sx={[
        {
          p: 2,
          gap: 2,
          bgcolor: 'background.surface',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gridColumn: '1 / -1',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 1100,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}

Header.propTypes = {
  sx: PropTypes.any,
  props: PropTypes.any,
};

export default Header;
