import PropTypes from 'prop-types';
import PaperBox from './paper-box';
import SubtitleBox from './subtitle-box';

const PaperSubtitleBox = ({ title, children }) => (
  <PaperBox>
    <SubtitleBox title={title}>{children}</SubtitleBox>
  </PaperBox>
);

export default PaperSubtitleBox;

PaperSubtitleBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};
