import { useState } from 'react';

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event, callback) => {
    setAnchorEl(event.currentTarget);

    if (callback != null) callback();
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return {
    anchorEl,
    open,
    onOpen,
    onClose,
  };
};

export default usePopover;
