const requestMethods = {
  delete: (connection, url, config, body) => connection.delete(url, { data: body, ...config }),
  get: (connection, url, config) => connection.get(url, config),
  post: (connection, url, config, body) => connection.post(url, body, config),
  put: (connection, url, config, body) => connection.put(url, body, config),
  postForm: (connection, url, config, body) => {
    const header = {
      'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
      ...config,
    };
    return connection.post(url, body, header);
  },
};

export default requestMethods;
