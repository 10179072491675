import PropTypes from 'prop-types';
import React from 'react';
import Latex from 'react-latex-next';
import restoreItemText from '../../../../components/functions/restoreItemText';
import PrimaryLink from '../../../../standard/primary/primary-link';
import Word from '../../../../standard/primary/word';
import symbols from '../../../../values/symbols';
import ContentImage from './content-image';
import ContentLineRow from './content-line-row';
import LineActionDetail from './line-action-detail';
import TextTab from './text-tab';

const ContentLine = ({ line, viewMode, onUpdate }) => {
  const renderContent = () => {
    const parts = line.bracketWords.split(';');

    switch (line.status) {
      case 'R':
        return (
          <PrimaryLink href={line.bracketWords} text={`${line.firstWord} ${line.otherWords}`} />
        );
      case 'B':
        return (
          <strong>
            {line.firstWord} {line.otherWords}
          </strong>
        );
      case 'M':
        return (
          <Latex>
            {line.firstWord} {line.otherWords}
          </Latex>
        );
      case 'U':
        return <ContentImage id={line.firstWord} />;
      case 't':
        return (
          <>
            ► {line.firstWord} {line.otherWords} <LineActionDetail label={line.bracketWords} />
          </>
        );
      case 'P':
        return (
          <>
            {line.firstWord} {line.otherWords} <LineActionDetail label={line.bracketWords} />
            <br />
            <br />
          </>
        );
      case 'T':
      case 'S':
        return (
          <>
            • {line.firstWord} {line.otherWords} <LineActionDetail label={line.bracketWords} />
          </>
        );
      case 'F':
        return (
          <>
            💰 {line.firstWord} {line.otherWords} <LineActionDetail label={line.bracketWords} />
          </>
        );
      case 'FR':
        return (
          <>
            <PrimaryLink href={parts[1]} text={`💰 ${line.firstWord} ${line.otherWords}`} />{' '}
            <LineActionDetail label={parts[0]} />
          </>
        );
      case 'Y':
        return (
          <ContentLineRow
            line={line}
            symbol={symbols.tick}
            viewMode={viewMode}
            onUpdate={onUpdate}
          />
        );
      case 'N':
        return (
          <ContentLineRow
            line={line}
            symbol={symbols.cross}
            viewMode={viewMode}
            onUpdate={onUpdate}
          />
        );
      case 'D':
        return (
          <ContentLineRow
            line={line}
            symbol={symbols.hold}
            viewMode={viewMode}
            onUpdate={onUpdate}
          />
        );
      case '~':
        return (
          <ContentLineRow
            line={line}
            symbol={symbols.inProgress}
            viewMode={viewMode}
            onUpdate={onUpdate}
          />
        );
      default:
        return restoreItemText(line, viewMode);
    }
  };

  return (
    <Word component="div">
      <TextTab depth={line.depth} viewMode={viewMode} />
      {renderContent()}
    </Word>
  );
};

ContentLine.propTypes = {
  line: PropTypes.object.isRequired,
  viewMode: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

export default ContentLine;
