import requestMethods from '../connection/requestMethods';

export const calendarApi = {
  getCalenderItemQuery: {
    method: requestMethods.get,
    path: (userId, action, bracketWords, day) =>
      `/calendar/item/query?userId=${userId}&action=${action}&bracketWords=${bracketWords}&day=${day}`,
    okMessage: 'Calendar query is fetched',
  },
  getCalenderItemPrediction: {
    method: requestMethods.get,
    path: (userId, action, percent, workload, days) =>
      `/calendar/item/predict?userId=${userId}&action=${action}&percent=${percent}&workload=${workload}&days=${days}`,
    okMessage: 'Calendar prediction is fetched',
  },
  getCalenderTodayItems: {
    method: requestMethods.get,
    path: (userId) => `/calendar/item/today?userId=${userId}`,
    okMessage: 'Calendar items are fetched',
  },
  getCalenderTodayItemsParsed: {
    method: requestMethods.get,
    path: (userId) => `/calendar/item/today/parsed?userId=${userId}`,
    okMessage: 'Parsed calendar items are fetched',
  },
  getCalenderOverviewQuery: {
    method: requestMethods.get,
    path: (userId, action) => `/calendar/overview/query?userId=${userId}&action=${action}`,
    okMessage: 'Calendar query is fetched',
  },
  getCalenderReport: {
    method: requestMethods.get,
    path: (userId) => `/calendar/report?userId=${userId}`,
    okMessage: 'Calendar report is fetched',
  },
  queryCalenderReport: {
    method: requestMethods.get,
    path: (userId, start, end, unit, name) =>
      `/calendar/report/query?userId=${userId}&start=${start}&end=${end}&unit=${unit}&name=${name}`,
    okMessage: 'Calendar query is fetched',
  },
  getCalenderByDateRange:{
    method: requestMethods.get,
    path: (userId, start, end) => `/calendar/range/d?userId=${userId}&start=${start}&end=${end}`,
    okMessage: 'Calendar contents are fetched',
  },
  getCalenderByRange: {
    method: requestMethods.get,
    path: (userId, date) => `/calendar/range?userId=${userId}&date=${date}`,
    okMessage: 'Calendar contents are fetched',
  },
  createCalendarContent: {
    method: requestMethods.post,
    path: (userId) => `/calendar/content?userId=${userId}`,
    okMessage: 'Calendar content is created',
  },
  updateCalendarContent: {
    method: requestMethods.put,
    path: (userId) => `/calendar/content?userId=${userId}`,
    okMessage: 'Calendar content is updated',
  },
  removeCalendarContent: {
    method: requestMethods.delete,
    path: (userId, id) => `/calendar/content?userId=${userId}&id=${id}`,
    okMessage: 'Calendar content is removed',
  },
  updateCalendarLine: {
    method: requestMethods.post,
    path: (userId) => `/calendar/line?userId=${userId}`,
    okMessage: 'Calendar line is updated',
  },
  removeCalendarLine: {
    method: requestMethods.delete,
    path: (userId) => `/calendar/line?userId=${userId}`,
    okMessage: 'Calendar line is removed',
  },
  duplicateCalendarLine: {
    method: requestMethods.post,
    path: (userId) => `/calendar/line/duplicate?userId=${userId}`,
    okMessage: 'Calendar line is duplicated',
  },
  getLastWeekReport: {
    method: requestMethods.get,
    path: (userId) => `/calendar/report/week?userId=${userId}`,
    okMessage: 'Calendar report is fetched',
  },
  insertCalendarLine: {
    method: requestMethods.post,
    path: (userId, contentId, lineId, offset, status, mainText, sideText) =>
      `/calendar/line/insert?userId=${userId}&contentId=${contentId}&lineId=${lineId}&offset=${offset}&status=${status}&mainText=${mainText}&sideText=${sideText}`,
    okMessage: 'Calendar line is duplicate',
  },
  getContentModel: (content, userId) => ({
    isAllDay: content.allDay,
    colorCode: content.color,
    text: content.description,
    startDate: new Date(content.start),
    endDate: new Date(content.end),
    name: content.title,
    id: content.id,
    userId,
  }),
  getNewContentModel: (content, userId) => ({
    isAllDay: content.allDay,
    colorCode: content.color,
    text: content.description,
    startDate: new Date(content.start),
    endDate: new Date(content.end),
    name: content.title,
    userId,
  }),
  mapContentToEvent: (contents) =>
    contents.map((content) => ({
      id: content.id,
      allDay: content.isAllDay,
      color: content.colorCode,
      textColor: content.colorCode,
      description: content.text,
      start: new Date(content.startDate).getTime(),
      end: new Date(content.endDate).getTime(),
      lastUpdatedAt: new Date(content.lastUpdatedAt),
      title: content.name,
      userInfoId: content.userInfoId,
    })),
};
