import { useCallback, useState } from 'react';

const useArray = (initialArray) => {
  const [value, setValue] = useState(initialArray ?? []);
  const isEmpty = value.length === 0;

  const onChangeValue = (newValue) => {
    setValue(newValue ?? []);
  };

  const onAppendElement = useCallback((newValue) => onChangeValue([...value, newValue]), [value]);

  const onModifyElement = useCallback(
    (i, newValue) => {
      if (i >= 0 && i <= value.length) {
        const newArray = [...value];
        newArray[i] = newValue;
        setValue(newArray);
      }
    },
    [value]
  );

  const onClear = () => onChangeValue([]);

  return {
    value,
    isEmpty,
    onChangeValue,
    onAppendElement,
    onModifyElement,
    onClear,
  };
};

export default useArray;
