import ListItemButton from '@mui/joy/ListItemButton';
import PropTypes from 'prop-types';

const PrimaryListItemButton = ({ children, ...others }) => (
  <ListItemButton {...others}>{children}</ListItemButton>
);

export default PrimaryListItemButton;

PrimaryListItemButton.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
