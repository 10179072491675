import PropTypes from 'prop-types';
import { Fragment } from 'react';

const getRepeatTab = (n) => (
  <>
    {[...Array(n)].map((_, i) => (
      <Fragment key={i}>&nbsp;</Fragment>
    ))}
  </>
);

const getTextTabSize = (viewMode) => {
  if (viewMode.platform === 'web') return 8;
  if (viewMode.platform === 'mobile') return 2;
  return 0;
};

const TextTab = ({ depth, viewMode }) => <>{getRepeatTab(depth * getTextTabSize(viewMode))}</>;

export default TextTab;

TextTab.propTypes = {
  depth: PropTypes.number,
  viewMode: PropTypes.object,
};
