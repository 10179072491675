const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PRODUCT: '/product',
};

export const paths = {
  termsOfService: '/terms-of-service',
  page404: '/404',
  login: '/login',
  forgetPassword: '/forget-password',
  register: '/register',
  product: {
    root: ROOTS.PRODUCT,
  },
  blog: {
    post: (path) => `/blog/${path}`,
  },
  dashboard: {
    root: `${ROOTS.DASHBOARD}/knowledge`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    user: {
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      admin: `${ROOTS.DASHBOARD}/user/admin`,
      subscription: {
        root: `${ROOTS.DASHBOARD}/user/subscription`,
      },
    },
    logistics: {
      calendar: `${ROOTS.DASHBOARD}/calendar`,
      gallery: {
        root: `${ROOTS.DASHBOARD}/gallery`,
        collection: (seriesId, collectionId) =>
          `${ROOTS.DASHBOARD}/gallery/collection/${seriesId}/${collectionId}`,
      },
      kpic: {
        root: `${ROOTS.DASHBOARD}/knowledge`,
        finance: `${ROOTS.DASHBOARD}/finance`,
        capacity: `${ROOTS.DASHBOARD}/capacity`,
        log: (id) => `${ROOTS.DASHBOARD}/knowledge/${id}`,
      },
      post: {
        root: `${ROOTS.DASHBOARD}/doc`,
        collection: (id) => `${ROOTS.DASHBOARD}/doc/${id}`,
      },
    },
    analytics: {
      post: `${ROOTS.DASHBOARD}/post`,
    },
  },
};
