import PropTypes from 'prop-types';
import { useBoolean } from '../../hooks/use-boolean';
import PrimaryListItem from '../primary/primary-list-item';
import PrimaryListItemButton from '../primary/primary-list-item-button';
import CustomCollapse from './custom-collapse';

const ListExpanderItem = ({ header, content }) => {
  const isExpand = useBoolean(false);

  return (
    <>
      <PrimaryListItem>
        <PrimaryListItemButton onClick={isExpand.onToggle}>{header}</PrimaryListItemButton>
      </PrimaryListItem>
      <PrimaryListItem>
        <CustomCollapse transitionIn={isExpand.value}>{content}</CustomCollapse>
      </PrimaryListItem>
    </>
  );
};

export default ListExpanderItem;

ListExpanderItem.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
};
