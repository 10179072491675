import LinearProgress from '@mui/joy/LinearProgress';
import Stack from '@mui/joy/Stack';
import PropTypes from 'prop-types';
import PrimaryTextField from '../primary/primary-text-field';
import Word from '../primary/word';

const PasswordTextField = ({ value, onChange, endDecorator, showStrength = true }) => {
  const minLength = 12;
  return (
    <Stack spacing={0.5} sx={{ '--hue': Math.min(value.length * 10, 120) }}>
      <PrimaryTextField
        type="password"
        label="Password"
        value={value}
        onChange={onChange}
        endDecorator={endDecorator}
      />
      {showStrength && (
        <>
          <LinearProgress
            determinate
            size="sm"
            value={Math.min((value.length * 100) / minLength, 100)}
            sx={{ bgcolor: 'background.level3', color: 'hsl(var(--hue) 80% 40%)' }}
          />
          <Word level="body-xs" sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}>
            {value.length < 3 && 'Very weak'}
            {value.length >= 3 && value.length < 6 && 'Weak'}
            {value.length >= 6 && value.length < 10 && 'Strong'}
            {value.length >= 10 && 'Very strong'}
          </Word>
        </>
      )}
    </Stack>
  );
};
export default PasswordTextField;

PasswordTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  endDecorator: PropTypes.node,
  showStrength: PropTypes.bool,
};
