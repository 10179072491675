import { Link } from '@mui/joy';
import Divider from '@mui/joy/Divider';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import { paymentApi } from '../../../../../api/user/payment';
import { UserContext } from '../../../../../auth/context/user';
import useObject from '../../../../../hooks/use-object';
import useString from '../../../../../hooks/use-string';
import { RouterLink } from '../../../../../routes/components';
import { useRouter } from '../../../../../routes/hook';
import { paths } from '../../../../../routes/paths';
import DoubleColumn from '../../../../../standard/complementary/double-column';
import HelmetWrapper from '../../../../../standard/complementary/helmet-wrapper';
import ListExpander from '../../../../../standard/complementary/list-expander';
import PageContainer from '../../../../../standard/complementary/page-container';
import PaperBox from '../../../../../standard/complementary/paper-box';
import PaperSubtitleBox from '../../../../../standard/complementary/paper-subtitle-box';
import SubtitleBox from '../../../../../standard/complementary/subtitle-box';
import useArray from '../../../../../standard/hooks/useArray';
import useInit from '../../../../../standard/hooks/useInit';
import useRequest from '../../../../../standard/hooks/useRequest';
import ColumnStack from '../../../../../standard/primary/column-stack';
import PrimaryChip from '../../../../../standard/primary/primary-chip';
import Word from '../../../../../standard/primary/word';

const SubscriptionPage = () => {
  const { userId } = useContext(UserContext);
  const { onRequest } = useRequest();
  const router = useRouter();

  const params = useParams();

  const subscriptionInfo = useObject(null);
  const paymentHistory = useArray([]);
  const paymentLink = useString('');

  const onGetSubscriptionInfo = useCallback(async () => {
    const response = await onRequest(paymentApi.getSubscriptionInfo, [userId], null, false);
    subscriptionInfo.onChangeValue(response?.result);
  }, [onRequest, subscriptionInfo, userId]);

  const onGetPaymentHistory = useCallback(async () => {
    const response = await onRequest(paymentApi.getPaymentHistory, [userId], null, false);
    paymentHistory.onChangeValue(response?.result);
  }, [onRequest, paymentHistory, userId]);

  const onGetPaymentLink = useCallback(async () => {
    const response = await onRequest(paymentApi.getPaymentLink, [userId], null, false);
    paymentLink.onChangeValue(response?.result);
  }, [onRequest, paymentLink, userId]);

  const onCreatePaymentSession = useCallback(
    async (sessionId) => {
      await onRequest(paymentApi.createPaymentSession, [userId, sessionId], null, true);
      router.push(paths.dashboard.account.subscription.root);
    },
    [onRequest, router, userId]
  );

  const onUpdate = useCallback(async () => {
    if (params?.sessionId != null) {
      await onCreatePaymentSession(params.sessionId);
    }
    await onGetPaymentLink();
    await onGetSubscriptionInfo();
    await onGetPaymentHistory();
  }, [
    onCreatePaymentSession,
    onGetPaymentHistory,
    onGetPaymentLink,
    onGetSubscriptionInfo,
    params.sessionId,
  ]);

  const info = subscriptionInfo.value;

  useInit(onUpdate);
  return (
    <HelmetWrapper>
      <PageContainer>
        <DoubleColumn
          leftColumn={
            <ColumnStack>
              <SubtitleBox title="Subscription">
                <PrimaryChip label={info?.subscriptionTier} />
                <br />
                <Word>
                  current payment amount: ${info?.currentPaymentAmount}{' '}
                  {info?.currentPaymentCurrency}
                </Word>
                <Word>
                  current payment date: {new Date(info?.currentPaymentDate).toLocaleString()}
                </Word>
                <br />
                <Word>
                  next payment amount: ${info?.nextPaymentAmount} {info?.nextPaymentCurrency}
                </Word>
                <Word>next payment date: {new Date(info?.nextPaymentDate).toLocaleString()}</Word>
              </SubtitleBox>
              <Divider />
              <SubtitleBox title="Payment History">
                <ListExpander
                  items={paymentHistory.value.map((payment) => ({
                    header: (
                      <Word>
                        {new Date(payment.createdAt).toLocaleString()} (${payment.totalAmount / 100}{' '}
                        {payment.currency})
                      </Word>
                    ),
                    content: (
                      <PaperBox>
                        <ColumnStack>
                          <Word>payment status: {payment.paymentStatus}</Word>
                          <Word>payment link id: {payment.paymentLinkId}</Word>
                          <Word>invoice id: {payment.invoiceId}</Word>
                          <Word>subscription id: {payment.subscriptionId}</Word>
                        </ColumnStack>
                      </PaperBox>
                    ),
                  }))}
                />
              </SubtitleBox>
            </ColumnStack>
          }
          rightColumn={
            <PaperSubtitleBox title="Upgrade Subscription">
              <Word>
                This link will direct you to Stripe payment page. Enter the email associated with
                THIS account. Please do NOT logout during payment.
              </Word>
              <br />
              <Link component={RouterLink} href={paymentLink.value}>
                Upgrade to Premium
              </Link>
            </PaperSubtitleBox>
          }
        />
      </PageContainer>
    </HelmetWrapper>
  );
};

export default SubscriptionPage;
