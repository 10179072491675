import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';
import ProductLayout from '../../layouts/product';
import SubscriptionPage from '../../pages/dashboard/account/admin/subscription';

const BlogPage = lazy(() => import('../../pages/blog'));
const AdminPage = lazy(() => import('../../pages/dashboard/account/admin'));
const TermsOfService = lazy(() => import('../../pages/others/terms-of-service'));
const PostPage = lazy(() => import('src/pages/dashboard/analytics/post'));
const DocumentPage = lazy(() => import('src/pages/dashboard/logistics/post/list'));
const PostDetailsPage = lazy(() => import('src/pages/dashboard/logistics/post/details'));
const KpicPage = lazy(() => import('src/pages/dashboard/kpic'));
const GalleryPage = lazy(() => import('src/pages/dashboard/logistics/gallery'));
const CalendarPage = lazy(() => import('src/pages/dashboard/logistics/calendar'));
const InfoPage = lazy(() => import('src/pages/dashboard/account/profile'));
const FinancePage = lazy(() => import('../../pages/dashboard/kpic/finance'));

const CapacityPage = lazy(() => import('../../pages/dashboard/kpic/capacity'));
const Page404 = lazy(() => import('../../pages/others/page404'));
const ProductPage = lazy(() => import('../../pages/product'));

export const dashboardRoutes = [
  {
    path: 'product',
    element: (
      <ProductLayout>
        <ProductPage />
      </ProductLayout>
    ),
  },
  {
    path: 'blog',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { element: <BlogPage />, index: true },
      { path: ':id', element: <BlogPage /> },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { path: 'post', element: <PostPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'gallery', element: <GalleryPage /> },
      { path: 'knowledge', element: <KpicPage /> },
      { path: 'finance', element: <FinancePage /> },
      { path: 'capacity', element: <CapacityPage /> },
      {
        path: 'doc',
        children: [
          { element: <DocumentPage />, index: true },
          { path: ':id', element: <PostDetailsPage /> },
        ],
      },
      {
        path: 'user',
        children: [
          { path: 'profile', element: <InfoPage /> },
          {
            path: 'subscription',
            element: <SubscriptionPage />,
            children: [{ path: ':sessionId', element: <SubscriptionPage /> }],
          },
          { path: 'admin', element: <AdminPage /> },
        ],
      },
    ],
  },
  {
    path: 'terms-of-service',
    element: (
      <DashboardLayout>
        <TermsOfService />
      </DashboardLayout>
    ),
  },
  { path: '404', element: <Page404 /> },
];
