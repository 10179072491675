import { useCallback, useState } from 'react';
import requestInstance from '../../api/connection/requestInstance';
import { useBoolean } from '../../hooks/use-boolean';
import useArray from './useArray';
import useNotification from './useNotification';

const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const errors = useArray([]);
  const isSuccess = useBoolean(false);
  const { notifySuccess, notifyError } = useNotification();

  const onRequest = useCallback(
    async (request, args, body, isLogOk) => {
      setIsLoading(true);

      let response;
      try {
        response = await requestInstance(request, args, body);
        if (response?.result?.errors) {
          errors.onChangeValue(response?.result?.errors);
        }
        if (response?.result?.isSuccess != null) {
          isSuccess.setValue(response?.result?.isSuccess);
        }
      } catch (error) {
        notifyError(error);
        return { result: null, ok: false };
      }

      if (response.ok) {
        if (isLogOk) {
          notifySuccess(request.okMessage);
        }
      } else {
        notifyError(response.errorMessage);
      }

      setIsLoading(false);
      return response;
    },
    [errors, isSuccess, notifyError, notifySuccess]
  );

  return { onRequest, isLoading, errors: errors.value, isSuccess };
};

export default useRequest;
