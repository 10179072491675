import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { imageApi } from '../../../../api/gallery/image';
import useString from '../../../../hooks/use-string';
import CircularProgressInfo from '../../../../standard/complementary/circular-progress-info';
import useInit from '../../../../standard/hooks/useInit';
import useRequest from '../../../../standard/hooks/useRequest';

const ContentImage = ({ id }) => {
  const imageLink = useString('');
  const { onRequest, isLoading } = useRequest();

  const onUpdate = useCallback(async () => {
    const response = await onRequest(imageApi.getDocumentImage, [id], null, false);
    imageLink.onChangeValue(response?.result);
  }, [id, imageLink, onRequest]);

  useInit(onUpdate);
  return <>{isLoading ? <CircularProgressInfo /> : <img src={imageLink.value} alt={id} style={{width:'100%'}}/>}</>;
};

export default ContentImage;

ContentImage.propTypes = {
  id: PropTypes.string,
};
