import PropTypes from 'prop-types';
import HeaderJoyUI from '../joy-ui/header-joyui';
import Header from '../joy-ui/layout/layout-header';

export default function ProductLayout({ children }) {
  return (
    <>
      <Header>
        <HeaderJoyUI />
      </Header>
      {children}
    </>
  );
}

ProductLayout.propTypes = {
  children: PropTypes.node,
};
