import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const HelmetWrapper = ({ title, description, children }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    {children}
  </>
);

export default HelmetWrapper;

HelmetWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
