import Stack from '@mui/joy/Stack';
import PropTypes from 'prop-types';

const RowStack = ({ children, ...others }) => (
  <Stack direction="row" spacing={2} sx={{ mb: 2 }} {...others}>
    {children}
  </Stack>
);

export default RowStack;

RowStack.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
