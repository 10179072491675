import PropTypes from 'prop-types';
import React from 'react';
import PrimaryChip from '../../../../standard/primary/primary-chip';

const LineActionDetail = ({ label }) =>
  label.length > 0 ? <PrimaryChip variant="outlined" label={label.replace('K-', '')} /> : <></>;

export default LineActionDetail;

LineActionDetail.propTypes = {
  label: PropTypes.string,
};
