import requestMethods from '../connection/requestMethods';

export const documentLineApi = {
  updateDocumentLine: {
    method: requestMethods.post,
    path: (userId) => `/doc/line?userId=${userId}`,
    okMessage: 'Document line is updated',
  },
  removeDocumentLine: {
    method: requestMethods.delete,
    path: (userId) => `/doc/line?userId=${userId}`,
    okMessage: 'Document line is removed',
  },
  duplicateDocumentLine: {
    method: requestMethods.post,
    path: (userId) => `/doc/line/duplicate?userId=${userId}`,
    okMessage: 'Document line is duplicate',
  },
  insertDocumentLine: {
    method: requestMethods.post,
    path: (userId, contentId, lineId, offset, status, mainText, sideText) =>
      `/doc/line/insert?userId=${userId}&contentId=${contentId}&lineId=${lineId}&offset=${offset}&status=${status}&mainText=${mainText}&sideText=${sideText}`,
    okMessage: 'Document line is duplicate',
  },
  syncDocumentLines: {
    method: requestMethods.post,
    path: (userId) =>
      `/doc/line/sync?userId=${userId}`,
    okMessage: 'Document line is synced',
  },
};
