import { Typography } from '@mui/joy';
import PropTypes from 'prop-types';

const Word = ({ children, ...others }) => <Typography {...others}>{children}</Typography>;

export default Word;

Word.propTypes = {
  children: PropTypes.any,
  others: PropTypes.string,
};
