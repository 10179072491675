import requestMethods from '../connection/requestMethods';

export const imageApi = {
  getImageOneUri: {
    method: requestMethods.get,
    path: (md5) => `/image/content/uri/one?md5=${md5}`,
    okMessage: 'Image is downloaded',
  },
  getImagePreviewUris: {
    method: requestMethods.post,
    path: (collectionId) => `/image/content/uri/preview/all?collectionId=${collectionId}`,
    okMessage: 'Preview images are downloaded',
  },
  updateImage: {
    method: requestMethods.put,
    path: () => `/image/content`,
    okMessage: 'Image is updated',
  },
  updateImageCollection: {
    method: requestMethods.put,
    path: (userId) => `/image/collection?userId=${userId}`,
    okMessage: 'Image collection is updated',
  },
  removeImage: {
    method: requestMethods.delete,
    path: (userId, id) => `/image/content/one?userId=${userId}&id=${id}`,
    okMessage: 'Image is removed',
  },
  removeImageCollection: {
    method: requestMethods.delete,
    path: (userId, id) => `/image/collection/one?userId=${userId}&id=${id}`,
    okMessage: 'Image collection is removed',
  },
  relocateImage: {
    method: requestMethods.put,
    path: () => `/image/content/one/relocate`,
    okMessage: 'Image is relocated',
  },
  getImageContentList: {
    method: requestMethods.get,
    path: (collectionId) => `/image/content/list?collectionId=${collectionId}`,
    okMessage: 'Image is fetched',
  },
  getImageFolderList: {
    method: requestMethods.get,
    path: (userId) => `/image/folder?userId=${userId}`,
    okMessage: 'Imag folders are fetched',
  },
  getImageSeriesList: {
    method: requestMethods.get,
    path: (userId) => `/image/series/list?userId=${userId}`,
    okMessage: 'Image is fetched',
  },
  getImageCollectionList: {
    method: requestMethods.get,
    path: (seriesId) => `/image/collection/list?seriesId=${seriesId}`,
    okMessage: 'Image is fetched',
  },
  createImageContent: {
    method: requestMethods.postForm,
    path: () => `/image/content/create`,
    okMessage: 'Image content is fetched',
  },
  createImageCollection: {
    method: requestMethods.post,
    path: (userId) => `/image/collection?userId=${userId}`,
    okMessage: 'Image collection is created',
  },
  createImageSeries: {
    method: requestMethods.post,
    path: () => `/image/series`,
    okMessage: 'Album created',
  },
  cropSuggestions: {
    method: requestMethods.post,
    path: (aspectRatio) => `/image/crop/one?aspectRatio=${aspectRatio}`,
    okMessage: 'Image crop suggestions are fetched',
  },
  uploadDocumentImage: {
    method: requestMethods.postForm,
    path: () => `/image/doc/create`,
    okMessage: 'Image is uploaded',
  },
  getDocumentImage: {
    method: requestMethods.get,
    path: (id) => `/image/doc?id=${id}`,
    okMessage: 'Image is fetched',
  },
  getImageMetadata: {
    method: requestMethods.get,
    path: (userId) => `/image/metadata?userId=${userId}`,
    okMessage: 'Image metadata is fetched',
  },
  onSyncGallery: {
    method: requestMethods.post,
    path: () => `/image/sync`,
    okMessage: 'Images are synced',
  },
  getCollectionPreview: {
    method: requestMethods.get,
    path: (id) => `/image/collection/preview?id=${id}`,
    okMessage: 'Image preview is synced',
  },
};
