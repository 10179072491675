import { List } from '@mui/joy';
import PropTypes from 'prop-types';
import ListExpanderItem from './list-expander-item';

const ListExpander = ({ items }) => (
  <List dense>
    {items.map((item, i) => (
      <ListExpanderItem key={i} content={item.content} header={item.header} />
    ))}
  </List>
);

export default ListExpander;

ListExpander.propTypes = {
  items: PropTypes.array,
};
