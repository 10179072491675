import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Box from '@mui/joy/Box';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';
import TextContent from '../../sections/document/view/document-content/text-content';
import DoubleColumn from '../../standard/complementary/double-column';
import PaperBox from '../../standard/complementary/paper-box';
import ColumnStack from '../../standard/primary/column-stack';
import PrimaryBox from '../../standard/primary/primary-box';
import PrimaryButton from '../../standard/primary/primary-button';
import RowStack from '../../standard/primary/row-stack';
import Word from '../../standard/primary/word';
import SecondaryButton from '../../standard/secondary/secondary-button';
import { contentLines } from './components/demo-values';
import StartIcon from './star.png';

const centerTextStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100%',
};

const ProductPage = () => {
  const router = useRouter();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '50vh',
          padding: 4,
          textAlign: 'left',
          backgroundColor: 'lightblue',
        }}
      >
        <Box sx={{ maxWidth: '50%' }}>
          <Word level="h1" gutterBottom sx={{ fontFamily: 'Roboto, sans-serif', color: 'navy' }}>
            MG Logi
          </Word>
          <Word level="h4" sx={{ fontFamily: 'Open Sans, sans-serif', color: 'navy' }}>
            All-in-one personal solution for managing everyday information.
          </Word>
          <br />
          <RowStack>
            <PrimaryButton fullWidth onClick={() => router.push(paths.register)}>
              Get Started
            </PrimaryButton>
            <SecondaryButton fullWidth variant="soft" onClick={null}>
              Learn More
            </SecondaryButton>
          </RowStack>
        </Box>
        <Box sx={{ textAlign: 'center', flex: 1, mt: { xs: 4, md: 0 } }}>
          <img
            style={{ width: '100%', maxWidth: '150px', height: 'auto' }}
            src={StartIcon}
            alt="icon"
          />
        </Box>
      </Box>

      <br />
      <br />
      <RowStack>
        <Box sx={centerTextStyle}>
          <EventAvailableIcon />
          <Word level="title-lg">Calendar</Word>
          <Word sx={{ width: '75%' }}>Mark down events and ongoing goals driven by analytics</Word>
        </Box>
        <Box sx={centerTextStyle}>
          <ChecklistIcon />
          <Word level="title-lg">Document</Word>
          <Word sx={{ width: '75%' }}>
            For note taking or project management. Flexible and easy-to-use.
          </Word>
        </Box>
        <Box sx={centerTextStyle}>
          <AttachMoneyIcon />
          <Word level="title-lg">Finance</Word>
          <Word sx={{ width: '75%' }}>
            Keep track of your budget with reports and plan for the future!{' '}
          </Word>
        </Box>
      </RowStack>

      <br />
      <br />
      <RowStack>
        <Box sx={centerTextStyle}>
          <CameraAltIcon />
          <Word level="title-lg">Photos</Word>
          <Word sx={{ width: '50%' }}>
            Simple photo albums. Support browser plugin to translate/upload screenshot.
          </Word>
        </Box>
        <Box sx={centerTextStyle}>
          <BookmarkBorderIcon />
          <Word level="title-lg">AI Library</Word>
          <Word sx={{ width: '50%' }}>
            AI-powered text highlighter. Supports everything from ZLibrary, news articles, online
            blogs with customization.
          </Word>
        </Box>
      </RowStack>
      <br />
      <br />
      <PaperBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <DoubleColumn
          leftColumn={
            <ColumnStack
              sx={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Ensure the parent container has a defined height
              }}
            >
              <Word level="h3">Calendar</Word>
              <ul>
                <li>Linked with Dashboard</li>
                <br />
                <li>Project and Event based</li>
                <br />
                <li>Productivity auto-calculated</li>
              </ul>
            </ColumnStack>
          }
          rightColumn={
            <PrimaryBox sx={{ flex: 1 }}>
              <img
                src="/product/images/calendar.png"
                alt="Demo"
                style={{ width: '100%', height: 'auto' }}
              />
            </PrimaryBox>
          }
        />
      </PaperBox>
      <br />
      <br />
      <PaperBox

        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <DoubleColumn
          leftColumn={
            <PrimaryBox sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width:'100%',
              height: '100%', // Ensure the parent container has a defined height
               }}>
              <TextContent lines={contentLines} onUpdate={() => {}} viewMode={{}} />
            </PrimaryBox>
          }
          rightColumn={
            <ColumnStack
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Ensure the parent container has a defined height
              }}
            >
              <Word level="h3">Documents</Word>
              <ul>
                <li>Multiple uses</li>
                <br />
                <li>For projects, to-do lists or notes</li>
                <br />
                <li>Productivity auto-calculated</li>
              </ul>
            </ColumnStack>
          }
        />
      </PaperBox>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};
export default ProductPage;
