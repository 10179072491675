import symbols from '../symbols';

const statusList = [
  { status: 'Y', symbol: symbols.tick },
  { status: 'N', symbol: symbols.cross },
  { status: 'D', symbol: symbols.hold },
  { status: '~', symbol: symbols.inProgress },
];

export default statusList;
