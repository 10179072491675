import Container from '@mui/joy/Container';
import PropTypes from 'prop-types';
import { useSettingsContext } from '../../components/settings';
import FooterSection from '../../pages/others/footer-section';

const PageContainer = ({ children, maxWidth }) => {
  const settings = useSettingsContext();
  const width = (settings.themeStretch ? false : maxWidth) ?? 'lg';

  return (
    <>
      <br />
      <Container maxWidth={width}>{children}</Container>
    </>
  );
};

export default PageContainer;

PageContainer.propTypes = {
  children: PropTypes.any,
  maxWidth: PropTypes.string,
};
