import Box from '@mui/joy/Box';
import PropTypes from 'prop-types';
import * as React from 'react';

function Root({ sx, ...props }) {
  return (
    <Box
      {...props}
      sx={[
        {
          bgcolor: 'background.surface',
          minHeight: '100vh',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}

export default Root;

Root.propTypes = {
  sx: PropTypes.any,
  props: PropTypes.any,
};
