import PropTypes from 'prop-types';
import PrimaryGrid from '../primary/primary-grid';

const DoubleColumn = ({ leftColumn, rightColumn }) => (
  <PrimaryGrid container spacing={4}>
    <PrimaryGrid item xs={12} md={6}>
      {leftColumn}
    </PrimaryGrid>
    <PrimaryGrid item xs={12} md={6}>
      {rightColumn}
    </PrimaryGrid>
  </PrimaryGrid>
);

export default DoubleColumn;

DoubleColumn.propTypes = {
  leftColumn: PropTypes.any.isRequired,
  rightColumn: PropTypes.any.isRequired,
};
