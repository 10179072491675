import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { UserContext } from './user-context';

export const UserProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState({
    userId: localStorage.getItem('userId'),
    username: localStorage.getItem('username'),
    userRole: localStorage.getItem('userRole'),
  });

  const setUserContext = useCallback((data) => {
    if (data != null) {
      Object.entries(data).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    }
    setContextValue(data);
  }, []);

  const value = useMemo(
    () => ({ ...contextValue, setUserContext }),
    [contextValue, setUserContext]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.any,
};
