import { Chip } from '@mui/joy';
import PropTypes from 'prop-types';

const PrimaryChip = ({ label, children, ...others }) => <Chip {...others}>{label}</Chip>;

export default PrimaryChip;

PrimaryChip.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
  others: PropTypes.any,
};
