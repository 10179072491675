import requestMethods from '../connection/requestMethods';

export const paymentApi = {
  getSubscriptionInfo: {
    method: requestMethods.get,
    path: (userId) => `/payment/sub?userId=${userId}`,
    okMessage: 'Payment history is fetched',
  },
  getPaymentHistory: {
    method: requestMethods.get,
    path: (userId) => `/payment/history?userId=${userId}`,
    okMessage: 'Payment history is fetched',
  },
  getPaymentLink: {
    method: requestMethods.get,
    path: (userId) => `/payment/link?userId=${userId}`,
    okMessage: 'Payment link is fetched',
  },
  createPaymentSession: {
    method: requestMethods.get,
    path: (userId, sessionId) => `/payment/success?userId=${userId}&sessionId=${sessionId}`,
    okMessage: 'Payment session is submitted',
  },
};
