const restoreItemText = (item, viewMode) => {
  const { depth, status, text, actionDetails } = item;
  const statusText = viewMode?.tab === 'tab' ? `(${status})` : '';
  let s = `${'\t'.repeat(depth)}${statusText} ${text}`;
  if (actionDetails?.length > 0) {
    s += ` (${actionDetails})`;
  }
  return s;
};

export default restoreItemText;
