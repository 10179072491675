const contentLineFactory = (depth, status, firstWord, otherWords, bracketWords) => ({
  id: Date.now(),
  depth,
  status,
  firstWord,
  otherWords,
  bracketWords,
});

export const contentLines = [
  contentLineFactory(0, 'Y', 'project', 'abc', 'tag'),
  contentLineFactory(1, 'Y', 'item', '1', ''),
  contentLineFactory(1, 'Y', 'item', '2', ''),
  contentLineFactory(1, 'N', 'item', '3', ''),
  contentLineFactory(1, 'N', 'item', '4', ''),
  contentLineFactory(2, 'N', 'item', '4.1', ''),
  contentLineFactory(1, '~', 'item', '5', ''),
  contentLineFactory(1, 'D', 'item', '6', ''),
  contentLineFactory(1, 'F', 'item', '7', '10k'),
  contentLineFactory(1, 'F', 'item', '8', '$125'),
  contentLineFactory(1, 'R', 'mglogi.com', '', 'https://mglogi.com/'),
  contentLineFactory(1, 'FR', 'mglogi.com', 'price', '$10;https://mglogi.com/'),
];
