import { paths } from '../../routes/paths';
import PrimaryLink from '../../standard/primary/primary-link';
import RowStack from '../../standard/primary/row-stack';
import Word from "../../standard/primary/word";

const FooterSection = () => (
  <footer
    style={{
      padding: '1rem',
      textAlign: 'center',
      borderTop: '1px solid #ccc',
      marginTop: '2rem',
    }}
  >
    <RowStack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PrimaryLink href="mailto: contact@mglogi.com" text="contact@mglogi.com" />
      <PrimaryLink href={paths.termsOfService} text="Terms of Service" />
    </RowStack>
  </footer>
);

export default FooterSection;
