import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-quill/dist/quill.snow.css';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { AuthConsumer } from 'src/auth/context/jwt';
import { UserProvider } from 'src/auth/context/user';
import { SettingsProvider } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ThemeProvider from 'src/theme';
import Router from './routes/sections';

const ErrorScreen = () => (
  <p>There are some errors in the website. Please contact the administrator.</p>
);

Sentry.init({
  dsn: 'https://f5f03b5bf69d562a9467d355411b82ba@o4507707337736192.ingest.us.sentry.io/4507791890317312',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
});

export default function App() {
  useScrollToTop();

  return (
    <UserProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'bold', // 'default' | 'bold'
          themeLayout: 'horizontal', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <SnackbarProvider>
            <AuthConsumer>
              <ErrorBoundary fallbackRender={<ErrorScreen />}>
                <Router />
              </ErrorBoundary>
            </AuthConsumer>
          </SnackbarProvider>
        </ThemeProvider>
      </SettingsProvider>
    </UserProvider>
  );
}
