import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListSubheader from '@mui/joy/ListSubheader';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { useRouter } from '../../routes/hook';
import navigationGroups from '../../values/layout/navigation-groups';

export default function NavigationJoyUi({ onClose }) {
  const router = useRouter();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleListItemClick = (index, path) => {
    setSelectedIndex(index);
    router.push(path);
    onClose();
  };

  const renderListItems = (items, parentIndex = '') =>
    items.map((item, index) => {
      const currentIndex = `${parentIndex}${index}`;
      return (
        <Fragment key={currentIndex}>
          <ListItem>
            <ListItemButton
              selected={selectedIndex === currentIndex}
              onClick={() => handleListItemClick(currentIndex, item.path)}
            >
              <ListItemDecorator>{item.icon}</ListItemDecorator>
              <ListItemContent>{item.name}</ListItemContent>
            </ListItemButton>
          </ListItem>
          {item.items && (
            <List
              aria-labelledby={`nav-list-${currentIndex}`}
              sx={{ paddingLeft: '20px', '& .JoyListItemButton-root': { p: '8px' } }}
            >
              {renderListItems(item.items, `${currentIndex}-`)}
            </List>
          )}
        </Fragment>
      );
    });

  return (
    <List size="sm" sx={{ '--ListItem-radius': 'var(--joy-radius-sm)', '--List-gap': '4px' }}>
      <ListItem nested>
        {navigationGroups.map((group, i) => (
          <Fragment key={i}>
            <ListSubheader sx={{ letterSpacing: '2px', fontWeight: '800' }}>
              {group.label}
            </ListSubheader>
            <List
              aria-labelledby="nav-list-browse"
              sx={{
                '& .JoyListItemButton-root': { p: '8px' },
              }}
            >
              {renderListItems(group.items, `${i}-`)}
            </List>
            <br />
          </Fragment>
        ))}
      </ListItem>
    </List>
  );
}

NavigationJoyUi.propTypes = {
  onClose: PropTypes.func,
};
