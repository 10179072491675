import Stack from '@mui/joy/Stack';
import PropTypes from 'prop-types';

const ColumnStack = ({ children, ...others }) => (
  <Stack direction="column" spacing={2} {...others}>
    {children}
  </Stack>
);

export default ColumnStack;

ColumnStack.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
