import { Launch } from '@mui/icons-material';
import Link from '@mui/joy/Link';
import PropTypes from 'prop-types';
import React from 'react';

const PrimaryLink = ({ href, text }) => (
  <Link startDecorator={<Launch />} href={href} target="_blank" rel="noopener noreferrer">
    {text}
  </Link>
);

export default PrimaryLink;

PrimaryLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
