import { Button } from '@mui/joy';
import PropTypes from 'prop-types';

const PrimaryButton = ({ children, onClick, disabled, fullWidth, startIcon, ...others }) => (
  <Button
    color="primary"
    variant="solid"
    onClick={onClick}
    disabled={disabled}
    fullWidth={fullWidth ?? false}
    startIcon={startIcon}
    {...others}
  >
    {children}
  </Button>
);

export default PrimaryButton;

PrimaryButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.any,
};
