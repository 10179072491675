import HomeIcon from '@mui/icons-material/Home';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/joy/Box';
import DialogTitle from '@mui/joy/DialogTitle';
import Drawer from '@mui/joy/Drawer';
import IconButton from '@mui/joy/IconButton';
import ModalClose from '@mui/joy/ModalClose';
import Stack from '@mui/joy/Stack';
import * as React from 'react';
import { paths } from 'src/routes/paths';
import useAuth from '../../pages/auth/hooks/use-auth';
import { useRouter } from '../../routes/hook';
import HeaderDropdown from './header-dropdown';
import HeaderQuickNote from './header-quick-note';
import NavigationJoyUi from './naviagation-joyui';

export default function HeaderJoyUI() {
  const router = useRouter();
  const { isAccountExist, isAccessTokenExist } = useAuth();

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <IconButton
          size="md"
          variant="outlined"
          color="neutral"
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
            borderRadius: '50%',
          }}
          onClick={() => router.push(paths.product.root)}
        >
          <HomeIcon />
        </IconButton>
      </Stack>
      <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <IconButton variant="plain" color="neutral" onClick={() => router.push(paths.product.root)}>
          <HomeIcon />
        </IconButton>

        <Drawer sx={{ display: { xs: 'inline-flex' } }} open={open} onClose={() => setOpen(false)}>
          <ModalClose />
          <DialogTitle>
            <NavigationJoyUi onClose={() => setOpen(false)} />
          </DialogTitle>
          <Box sx={{ px: 1 }} />
        </Drawer>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        {isAccessTokenExist && <HeaderQuickNote />}
        <HeaderDropdown />
      </Box>
    </Box>
  );
}
