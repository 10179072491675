import { useState } from 'react';

const useObject = (obj) => {
  const [value, setValue] = useState(obj);

  const onChangeValue = (newObj) => {
    setValue(newObj);
  };

  return {
    value,
    onChangeValue,
  };
};

export default useObject;
