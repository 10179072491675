const parseFinance = (lines) => {
  const parseAmount = (amountString) => {
    // Remove any non-numeric characters except for '.' and '-'
    const cleanedString = amountString.replace(/[^0-9.-]/g, '');

    // Handle 'k' for thousands
    let multiplier = 1;
    if (amountString.toLowerCase().includes('k')) {
      multiplier = 1000;
    }

    // Parse the number and apply the multiplier
    const parsedNumber = parseFloat(cleanedString) * multiplier;

    return Number.isNaN(parsedNumber) ? null : parsedNumber;
  };

  const items = lines?.filter((line) => line.status === 'F' || line.status==='FR') ?? [];
  const amountSum = items
    .map((line) => parseAmount(line.bracketWords.split(';')[0]))
    .reduce((total, amount) => total + amount, 0);

  return {
    items,
    amountSum,
    isEmpty: items.length === 0,
  };
};

export default parseFinance;
