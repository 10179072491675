import { Grid } from '@mui/joy';
import PropTypes from 'prop-types';

const PrimaryGrid = ({ children, ...others }) => <Grid {...others}>{children}</Grid>;

export default PrimaryGrid;

PrimaryGrid.propTypes = {
  children: PropTypes.any,
  others: PropTypes.any,
};
