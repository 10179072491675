import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DataArrayIcon from '@mui/icons-material/DataArray';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import { paths } from '../../routes/paths';

const kpicItems = [
  {
    name: 'Knowledge',
    path: paths.dashboard.logistics.kpic.root,
    icon: <DataArrayIcon />,
  },
  {
    name: 'Finance',
    path: paths.dashboard.logistics.kpic.finance,
    icon: <AttachMoneyIcon />,
  },
  {
    name: 'Capacity',
    path: paths.dashboard.logistics.kpic.capacity,
    icon: <BatteryCharging80Icon />,
  },
];

const logisticsItems = [
  {
    name: 'Calendar',
    path: paths.dashboard.logistics.calendar,
    icon: <CalendarTodayIcon />,
  },
  {
    name: 'Document',
    path: paths.dashboard.logistics.post.root,
    icon: <FolderOpenIcon />,
  },
  {
    name: 'Photos',
    path: paths.dashboard.logistics.gallery.root,
    icon: <PhotoCameraBackIcon />,
  },
];

const analyticsItems = [
  {
    name: 'Post',
    path: paths.dashboard.analytics.post,
    icon: <BookmarkBorderIcon />,
  },
];

const navigationGroups = [
  { label: 'Kpic', items: kpicItems },
  { label: 'Logistics', items: logisticsItems },
  { label: 'Analytics', items: analyticsItems },
];

export default navigationGroups;
