import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Word from '../../../../standard/primary/word';
import statusList from '../../../../values/document/status-list';

const StatusSelector = ({ popover, onUpdateStatus, line, symbol }) => {
  const { anchorEl, open, onOpen, onClose } = popover;
  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        {statusList.map((s) => (
          <MenuItem key={s.status} onClick={() => onUpdateStatus(s.status)}>
            <Word>{s.symbol}</Word>
          </MenuItem>
        ))}
        <MenuItem onClick={() => onUpdateStatus('duplicate')}>
          <Word>+1</Word>
        </MenuItem>
        <MenuItem onClick={() => onUpdateStatus('delete')}>
          <DeleteIcon />
        </MenuItem>
      </Menu>{' '}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span onClick={open ? onClose : onOpen}>{symbol}</span> {line.firstWord} {line.otherWords}{' '}
    </>
  );
};

export default StatusSelector;

StatusSelector.propTypes = {
  popover: PropTypes.object,
  onUpdateStatus: PropTypes.func,
  line: PropTypes.object,
  symbol: PropTypes.string,
};
