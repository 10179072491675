import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useCallback, useEffect, useState } from 'react';
import { userApi } from '../../../api/user/user';
import { useBoolean } from '../../../hooks/use-boolean';
import useString from '../../../hooks/use-string';
import { useRouter } from '../../../routes/hook';
import { paths } from '../../../routes/paths';
import CustomCollapse from '../../../standard/complementary/custom-collapse';
import PaperBox from '../../../standard/complementary/paper-box';
import PasswordTextField from '../../../standard/complementary/password-text-field';
import SubtitleBoxWithLeftButton from '../../../standard/complementary/subtitle-box-with-left-button';
import useRequest from '../../../standard/hooks/useRequest';
import ColumnStack from '../../../standard/primary/column-stack';
import PrimaryButton from '../../../standard/primary/primary-button';
import PrimaryTextField from '../../../standard/primary/primary-text-field';
import { validateEmail } from '../../../utils/validations';

const ForgetPasswordPanel = () => {
  const router = useRouter();
  const { onRequest } = useRequest();

  const email = useString('');
  const verificationCode = useString('');
  const newPassword = useString('');

  const isVerificationCodeSent = useBoolean(false);
  const isVerifyOk = useBoolean(false);

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const onForgetPassword = useCallback(async () => {
    await onRequest(userApi.forgetPassword, [email.value], null, true);
    isVerificationCodeSent.onTrue();
    setCountdown(60);
  }, [email.value, isVerificationCodeSent, onRequest]);

  const onVerifyCode = useCallback(async () => {
    const response = await onRequest(
      userApi.verifyForgetPassword,
      [email.value, verificationCode.value],
      null,
      true
    );

    if (response?.result) {
      isVerifyOk.onTrue();
    }
  }, [onRequest, email.value, verificationCode.value, isVerifyOk]);

  const onResetPassword = useCallback(async () => {
    const body = {
      code: verificationCode.value,
      email: email.value,
      password: newPassword.value,
    };
    const response = await onRequest(userApi.resetPassword, [], body, true);
    if (response?.result) {
      isVerifyOk.onTrue();
    }
  }, [verificationCode.value, email.value, newPassword.value, onRequest, isVerifyOk]);

  return (
    <PaperBox>
      <SubtitleBoxWithLeftButton
        menuItems={[
          {
            icon: <ArrowBackIcon />,
            onClick: () => router.push(paths.login),
          },
        ]}
        title="Forget password"
      >
        <ColumnStack>
          <br />
          <PrimaryTextField
            label="email"
            value={email.value}
            onChange={email.onChangeValue}
            validate={validateEmail}
            errorMessage="Please enter a valid email address"
            startDecorator={<MailOutlineIcon />}
            endDecorator={
              <PrimaryButton
                fullWidth
                onClick={onForgetPassword}
                disabled={email.isEmpty || countdown > 0}
              >
                {countdown > 0 ? `Resend in ${countdown}s` : 'Send verification code'}
              </PrimaryButton>
            }
          />

          <ColumnStack>
            <PrimaryTextField
              label="verification code"
              value={verificationCode.value}
              onChange={verificationCode.onChangeValue}
              endDecorator={
                <PrimaryButton
                  fullWidth
                  onClick={onVerifyCode}
                  disabled={
                    (!isVerificationCodeSent.value || verificationCode.isEmpty) && !isVerifyOk.value
                  }
                >
                  verify
                </PrimaryButton>
              }
            />
          </ColumnStack>
          <CustomCollapse transitionIn={isVerifyOk.value}>
            <ColumnStack>
              <PasswordTextField
                value={newPassword.value}
                onChange={newPassword.onChangeValue}
                endDecorator={
                  <PrimaryButton
                    fullWidth
                    onClick={onResetPassword}
                    disabled={email.isEmpty || verificationCode.isEmpty || newPassword.isEmpty}
                  >
                    reset
                  </PrimaryButton>
                }
              />
            </ColumnStack>
          </CustomCollapse>
        </ColumnStack>
      </SubtitleBoxWithLeftButton>
    </PaperBox>
  );
};

export default ForgetPasswordPanel;

ForgetPasswordPanel.propTypes = {};
