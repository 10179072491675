// @mui
import Box from "@mui/joy/Box";
import PropTypes from "prop-types";
// components
import { useSettingsContext } from "src/components/settings";
// hooks
import MainJoyUi from "../joy-ui/main-joyui";
//

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  if (isHorizontal) {
    return <MainJoyUi>{children}</MainJoyUi>;
  }

  if (isMini) {
    return (
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        minHeight: 1,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <MainJoyUi>{children}</MainJoyUi>
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
