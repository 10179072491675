import { FormControl } from '@mui/joy';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useBoolean } from '../../hooks/use-boolean';

const PrimaryTextField = ({
  fullWidth,
  value,
  label,
  onChange,
  placeholder,
  rows,
  startDecorator,
  endDecorator,
  validate,
  errorMessage,
  ...others
}) => {
  const error = useBoolean(false);

  const handleChange = useCallback(
    (newValue) => {
      onChange(newValue);
      if (validate) {
        error.setValue(!validate(newValue));
      }
    },
    [error, onChange, validate]
  );

  return (
    <FormControl fullWidth={fullWidth} error={error.value}>
      <FormLabel>{label}</FormLabel>
      <Input
        fullWidth={fullWidth}
        size="small"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        rows={rows ?? 1}
        startDecorator={startDecorator}
        endDecorator={endDecorator}
        multiline
        {...others}
      />
      {error.value && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default PrimaryTextField;

PrimaryTextField.propTypes = {
  fullWidth: PropTypes.bool,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  others: PropTypes.any,
  startDecorator: PropTypes.any,
  endDecorator: PropTypes.any,
  validate: PropTypes.func,
  errorMessage: PropTypes.string,
};
