import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Avatar from '@mui/joy/Avatar';
import Divider from "@mui/joy/Divider";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../../auth/context/user';
import useAuth from '../../pages/auth/hooks/use-auth';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';
import PrimaryButton from '../../standard/primary/primary-button';
import SecondaryButton from '../../standard/secondary/secondary-button';
import { isDevUser } from "../../utils/user-role";

const HeaderDropdown = () => {
  const router = useRouter();
  const { userRole } = useContext(UserContext);
  const { isAccountExist, isAccessTokenExist, onSignOut } = useAuth();

  return (
    <Dropdown>
      {!isAccountExist && !isAccessTokenExist ? (
        <>
          <SecondaryButton variant="plain" onClick={() => router.push(paths.login)}>
            Login
          </SecondaryButton>
          <PrimaryButton onClick={() => router.push(paths.register)}>Get Started</PrimaryButton>
        </>
      ) : (
        <MenuButton
          variant="plain"
          size="sm"
          sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
        >
          <Avatar src={PersonIcon} sx={{ maxWidth: '32px', maxHeight: '32px' }} />
        </MenuButton>
      )}

      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: '99999',
          gap: 1,
          '--ListItem-radius': 'var(--joy-radius-sm)',
        }}
      >
        <MenuItem onClick={() => router.push(paths.dashboard.user.profile)}>
          <PersonIcon />
          Profile
        </MenuItem>
        <Divider/>
        {isDevUser(userRole) && (
          <>
            <MenuItem onClick={() => router.push(paths.dashboard.user.subscription.root)}>
              <ShoppingCartIcon />
              Subscription
            </MenuItem>
            <MenuItem onClick={() => router.push(paths.dashboard.user.admin)}>
              <MusicNoteIcon />
              Admin
            </MenuItem>
          </>
        )}
        <MenuItem onClick={onSignOut}>
          <LogoutRoundedIcon />
          Logout
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default HeaderDropdown;
