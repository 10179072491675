import { CssBaseline } from '@mui/joy';
import { CssVarsProvider, extendTheme as extendJoyTheme } from '@mui/joy/styles';
import { deepmerge } from '@mui/utils';
import PropTypes from 'prop-types';
import { useSettingsContext } from 'src/components/settings';
import RTL from './options/right-to-left';

export default function ThemeProvider({ children }) {
  const settings = useSettingsContext();

  const joyTheme = extendJoyTheme();
  const mergedTheme = {
    ...joyTheme,
    colorSchemes: joyTheme.colorSchemes,
    typography: {
      ...joyTheme.typography,
    },
  };

  mergedTheme.generateCssVars = (colorScheme) => ({
    css: {
      ...joyTheme.generateCssVars(colorScheme).css,
    },
    vars: deepmerge(joyTheme.generateCssVars(colorScheme).vars),
  });
  mergedTheme.unstable_sxConfig = {
    ...joyTheme.unstable_sxConfig,
  };
  // mergedTheme.components = merge(componentsOverrides(mergedTheme), contrastOption.components);

  return (
    <CssVarsProvider theme={mergedTheme}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline />
        {children}
      </RTL>
    </CssVarsProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
