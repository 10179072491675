import requestMethods from '../connection/requestMethods';

export const userApi = {
  getUserInfo: {
    method: requestMethods.get,
    path: (userId) => `/user/info?userId=${userId}`,
    okMessage: 'User Info is fetched',
  },
  registerAccount: {
    method: requestMethods.post,
    path: () => `/user/register`,
    okMessage: `Account is registered`,
  },
  forgetPassword: {
    method: requestMethods.post,
    path: (email) => `/user/forget-password?email=${email}`,
    okMessage: `Verification code is sent. Please check your email box.`,
  },
  resetPassword: {
    method: requestMethods.post,
    path: () => `/user/reset-password`,
    okMessage: `Password reset is done. Please login again.`,
  },
  verifyForgetPassword: {
    method: requestMethods.post,
    path: (email, code) => `/user/forget-password/verify?email=${email}&code=${code}`,
    okMessage: `Verification code is verified.`,
  },
  loginAccount: {
    method: requestMethods.post,
    path: () => `/user/login`,
    okMessage: `Login is successful`,
  },
  getUserKeyValue: {
    method: requestMethods.get,
    path: (userId, type, key) => `/user/kv/one?userId=${userId}&type=${type}&key=${key}`,
    okMessage: 'Key value is fetched',
  },
  getUserKeyValues: {
    method: requestMethods.get,
    path: (userId) => `/user/kv/list?userId=${userId}`,
    okMessage: 'Key values are fetched',
  },
  getUserKeyValuesByType: {
    method: requestMethods.get,
    path: (userId, type) => `/user/kv/query?userId=${userId}&type=${type}`,
    okMessage: 'Key values are fetched',
  },
  createUserKeyValue: {
    method: requestMethods.post,
    path: (userId) => `/user/kv?userId=${userId}`,
    okMessage: 'Key values are updated',
  },
  updateUserKeyValue: {
    method: requestMethods.put,
    path: (userId) => `/user/kv?userId=${userId}`,
    okMessage: 'Key values are updated',
  },
  removeUserKeyValue: {
    method: requestMethods.delete,
    path: (userId, id) => `/user/kv?userId=${userId}&id=${id}`,
    okMessage: 'Key value is removed',
  },
  getUserList: {
    method: requestMethods.get,
    path: (userId) => `/user/list?userId=${userId}`,
    okMessage: 'User list fetched',
  },
};
