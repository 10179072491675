import { Box } from '@mui/joy';
import PropTypes from 'prop-types';
import * as React from 'react';

const CustomCollapse = ({ children, transitionIn }) => (
  <Box
    sx={{
      overflow: 'hidden',
      transition: 'height 0.3s ease',
      height: transitionIn ? 'auto' : 0,
    }}
  >
    {children}
  </Box>
);

CustomCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  transitionIn: PropTypes.bool.isRequired,
};

export default CustomCollapse;
