import PropTypes from 'prop-types';
import { useState } from 'react';
import HeaderJoyUI from './header-joyui';
import Header from './layout/layout-header';
import Root from './layout/root';

const MainJoyUi = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <Root
      sx={{
        ...(drawerOpen && {
          height: '100vh',
          overflow: 'hidden',
        }),
      }}
    >
      <Header>
        <HeaderJoyUI />
      </Header>
      {children}
    </Root>
  );
};

export default MainJoyUi;

MainJoyUi.propTypes = {
  children: PropTypes.node,
};
