import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthClassicLayout from '../../layouts/auth/classic';
import ProductLayout from '../../layouts/product';
import ForgetPasswordPanel from '../../pages/auth/components/forget-password-panel';
import ProductPage from '../../pages/product';
import { dashboardRoutes } from './dashboard';

const LoginPage = lazy(() => import('src/pages/auth'));
const RegisterPage = lazy(() => import('src/pages/auth/components/register-page'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <ProductLayout>
          <ProductPage />
        </ProductLayout>
      ),
    },
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
      children: [
        {
          path: ':status',
          element: (
            <AuthClassicLayout>
              <LoginPage />
            </AuthClassicLayout>
          ),
        }
      ],
    },
    {
      path: 'forget-password',
      element: (
        <AuthClassicLayout>
          <ForgetPasswordPanel />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout>
          <RegisterPage />
        </AuthClassicLayout>
      ),
      children: [
        {
          path: ':status',
          element: (
            <AuthClassicLayout>
              <RegisterPage />
            </AuthClassicLayout>
          ),
        }
      ],
    },
    ...dashboardRoutes,

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
