// @mui
import Stack from "@mui/joy/Stack";
import PropTypes from "prop-types";
// auth
// routes
// hooks
// theme
// components

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children }) {
  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 680,
        px: { xs: 2, md: 8 },
        py: { xs: 15, md: 30 },
      }}
    >
      {children}
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
};
