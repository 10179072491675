import PropTypes from 'prop-types';
import React from 'react';
import PrimaryBox from '../primary/primary-box';
import Word from '../primary/word';

const SubtitleBox = ({ children, title, ...others }) => (
  <PrimaryBox {...others}>
    <Word level="subtitle1" component="h2" sx={{ fontWeight: 'bold' }}>
      {title}
    </Word>
    {children}
  </PrimaryBox>
);

export default SubtitleBox;

SubtitleBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  others: PropTypes.any,
};
