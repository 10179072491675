import CircularProgress from '@mui/joy/CircularProgress';
import PropTypes from "prop-types";
import PrimaryBox from '../primary/primary-box';

const CircularProgressInfo = ({ size = 'md' }) => (
  <PrimaryBox
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={size} color="primary" />
  </PrimaryBox>
);

export default CircularProgressInfo;

CircularProgressInfo.propTypes = {
  size: PropTypes.string,
};
