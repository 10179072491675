import PublishIcon from '@mui/icons-material/Publish';
import IconButton from '@mui/joy/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { calendarApi } from '../../../../api/calendar/calendarAdapter';
import { documentLineApi } from '../../../../api/document/documentLine';
import { UserContext } from '../../../../auth/context/user';
import useString from '../../../../hooks/use-string';
import CircularProgressInfo from '../../../../standard/complementary/circular-progress-info';
import useRequest from '../../../../standard/hooks/useRequest';
import PrimaryBox from '../../../../standard/primary/primary-box';
import PrimaryTextField from '../../../../standard/primary/primary-text-field';
import RowStack from '../../../../standard/primary/row-stack';

const ContentLineInsertInput = ({ viewMode, line, isUp, onClose, onUpdate }) => {
  const { userId } = useContext(UserContext);
  const { onRequest, isLoading } = useRequest();

  const status = useString('');
  const mainText = useString('');
  const sideText = useString('');

  const onCreate = useCallback(async () => {
    const api =
      viewMode.type === 'document'
        ? documentLineApi.insertDocumentLine
        : calendarApi.insertCalendarLine;

    await onRequest(
      api,
      [
        userId,
        line?.documentContentId ?? line.calendarContentId,
        line.id,
        isUp ? 0 : 1,
        status.value,
        mainText.value,
        sideText.value,
      ],
      null,
      false
    );
    status.onClear();
    mainText.onClear();
    sideText.onClear();
    onClose();
    await onUpdate();
  }, [
    isUp,
    line.calendarContentId,
    line?.documentContentId,
    line.id,
    mainText,
    onClose,
    onRequest,
    onUpdate,
    sideText,
    status,
    userId,
    viewMode.type,
  ]);

  return (
    <PrimaryBox>
      <RowStack>
        <PrimaryTextField
          sx={{ width: '64px' }}
          onChange={status.onChangeValue}
          label=""
          placeholder="status"
        />
        <PrimaryTextField
          fullWidth
          onChange={mainText.onChangeValue}
          label=""
          placeholder="description"
        />
        <PrimaryTextField
          fullWidth
          onChange={sideText.onChangeValue}
          label=""
          placeholder="tag (optional)"
        />
        <IconButton onClick={onCreate} size="sm" disabled={status.isEmpty || mainText.isEmpty}>
          {isLoading ? <CircularProgressInfo size="sm" /> : <PublishIcon />}
        </IconButton>
      </RowStack>
    </PrimaryBox>
  );
};

export default ContentLineInsertInput;

ContentLineInsertInput.propTypes = {
  viewMode: PropTypes.object,
  line: PropTypes.object,
  isUp: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};
